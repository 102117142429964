
import { Component, Vue } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { apiCategoryLists,apiRedpacketCategoryAdd, apiRedpacketCategoryDetail, apiRedpacketCategoryEdit } from '@/api/application/redpacket_mall'
@Component({
    components: {
        MaterialSelect
    }
})
export default class AddBrand extends Vue {
    $refs!: { form: any }
    id!: any
    loading = false

  categoryList = []
  hasPid = 0
    form = {
        name: '',
      pid: '',
        image: '',
        sort: '',
        is_show: 1
    }

    rules = {
        name: [
            {
                required: true,
                message: '请输入分类名称',
                trigger: ['blur', 'change']
            }
        ]
    }

    handleSave() {
        this.$refs.form.validate((valid: boolean, object: any) => {
            if (valid) {
                const ref = /[^0-9]/g
                if(ref.test(this.form.sort)) {
                    this.$message.error('排序必须是数字')
                    return
                }
              if (!this.hasPid) {
                this.form.pid = ''
              }
                const api = this.id ? apiRedpacketCategoryEdit(this.form) : apiRedpacketCategoryAdd(this.form)
                api.then(() => {
                    this.$router.go(-1)
                })
            } else {
                return false
            }
        })
    }
    getBrandDetail() {
        this.loading = true
        apiRedpacketCategoryDetail(this.id).then((res: any) => {
          if (res.pid) {
            this.hasPid = 1
          }
            this.form = res
            this.loading = false
        })
    }


  getCategoryList() {
    apiCategoryLists({ page_type: 1 }).then((res: any) => {
      res?.lists.forEach((item: any) => {
        item.sons &&
        item.sons.forEach((sitem: any) => {
          delete sitem.sons
        })
      })
      this.categoryList = res?.lists
    })
  }


    created() {
        this.id = this.$route.query.id
        this.id && this.getBrandDetail()
      this.getCategoryList()
    }
}
